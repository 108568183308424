import Image from 'next/image';
import { Text } from '@anchorage/common/dist/components';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import { PORTO_SELF_REGISTRATION_PAGES, PORTO_SELF_REGISTRATION_PAGE_TITLE, getPortoSelfRegistrationPageImage } from 'components/porto/Signup/helpers';
import css from './styles.module.scss';
import { getLoginLink } from '../PageContent/PageContent';
type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
};
function PageSideBanner({
  page
}: Props) {
  return <div className={css.container} data-sentry-component="PageSideBanner" data-sentry-source-file="PageSideBanner.tsx">
      <div className={css.contentContainer}>
        <PortoLogoIcon className={css.anchorageLogo} data-sentry-element="PortoLogoIcon" data-sentry-source-file="PageSideBanner.tsx" />
        <div className={css.content}>
          <Image loading="eager" className={css.image} src={getPortoSelfRegistrationPageImage(PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE)} priority alt="page image" hidden={page !== PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE} data-sentry-element="Image" data-sentry-source-file="PageSideBanner.tsx" />
          <Image loading="eager" className={css.image} src={getPortoSelfRegistrationPageImage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS)} alt="page image" hidden={page !== PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS} data-sentry-element="Image" data-sentry-source-file="PageSideBanner.tsx" />
          <Image loading="eager" className={css.image} src={getPortoSelfRegistrationPageImage(PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM)} alt="page image" hidden={page !== PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM} data-sentry-element="Image" data-sentry-source-file="PageSideBanner.tsx" />
          <Image loading="eager" className={css.image} src={getPortoSelfRegistrationPageImage(PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE)} alt="page image" hidden={page !== PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE} data-sentry-element="Image" data-sentry-source-file="PageSideBanner.tsx" />
          <Text type="heading" size="huge" className={css.pageTitle} data-sentry-element="Text" data-sentry-source-file="PageSideBanner.tsx">
            {PORTO_SELF_REGISTRATION_PAGE_TITLE[page]}
          </Text>
        </div>
        <div className={css.footer}>
          <Text data-sentry-element="Text" data-sentry-source-file="PageSideBanner.tsx">
            {`${'Already have an account? '}`}
            <a className={css.footerLink} href={getLoginLink()}>
              Log in
            </a>
          </Text>
        </div>
      </div>
    </div>;
}
export default PageSideBanner;