import { Button } from '@latitude/button';
import { useEffect } from 'react';
import { Text } from '@anchorage/common/dist/components';
import { useFieldArray, useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormInput, FormSelect } from '@anchorage/common/dist/components/Form/components';
import { AddCircleIcon, TrashBinIcon } from '@anchorage/common/dist/components/Icons';
import { SelectOption } from '@anchorage/common/dist/components/SelectV2/types';
import css from './styles.module.scss';
const MIN_USERS = 2;
const MIN_USERS_REQUIRED = 1;
type Props = {
  countryOptions: SelectOption<string, string>[];
  isCountryOptionsLoading: boolean;
};
const AddYourTeamContent = ({
  countryOptions,
  isCountryOptionsLoading
}: Props) => {
  const {
    control
  } = useFormContext();
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'authorizedUser',
    rules: {
      minLength: MIN_USERS_REQUIRED
    }
  });
  useEffect(() => {
    if (fields.length < MIN_USERS) {
      append({
        email: '',
        firstName: '',
        lastName: '',
        country: ''
      });
    }
  }, [fields.length, append]);
  return <div data-sentry-component="AddYourTeamContent" data-sentry-source-file="AddYourTeamContent.tsx">
      {fields.map((field, index) => <div className={css.group} key={field.id}>
          {fields.length > MIN_USERS && <Button htmlType="button" variant="neutral" type="ghost" className={css.removeButton} onClick={() => remove(index)} data-testid="remove-button">
              <TrashBinIcon className={css.removeIcon} />
            </Button>}
          <FormInput key={`${field.id}-email`} control={control} name={`authorizedUser.${index}.email`} label="Email" containerClassName={css.email} type="email" required />
          <FormInput key={`${field.id}-firstName`} control={control} name={`authorizedUser.${index}.firstName`} label="First name" containerClassName={css.name} required />
          <FormInput key={`${field.id}-lastName`} control={control} name={`authorizedUser.${index}.lastName`} label="Last name" containerClassName={css.name} required />
          <FormSelect control={control} key={`${field.id}-country`} name={`authorizedUser.${index}.country`} label="Country of residence" options={countryOptions} required isLoading={isCountryOptionsLoading} containerClassName={css.country} />
        </div>)}
      <Text role="button" onClick={() => append({
      email: '',
      firstName: '',
      lastName: '',
      country: ''
    })} className={css.addUser} data-sentry-element="Text" data-sentry-source-file="AddYourTeamContent.tsx">
        <AddCircleIcon className={css.icon} data-sentry-element="AddCircleIcon" data-sentry-source-file="AddYourTeamContent.tsx" />
        Add user
      </Text>
    </div>;
};
export { AddYourTeamContent };